import React from "react"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Heading from "@components/elements/heading"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Anchor from "@components/elements/anchor"
import { graphql } from "gatsby"

import { processImages } from "@utils/process-images"
import Image from "@components/image"

const BrandingGuidelinesPage = ({ data }) => {
  const cardSettings = {
    gap: "3rem",
    borderRadius: "0.5rem",
    background: "white",
    padding: "2rem",
  }

  const images = processImages(data.images.edges)

  return (
    <Layout
      title={`Klipfolio Brand Guidelines`}
      description={`Guidelines & best practices for using Klipfolio's brand.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Container fullWidth noPadding>
        <Flex gap="6rem" gapSm="4rem">
          <Flex>
            <Container>
              <Heading
                fontSize="3rem"
                as="h1"
                padding="12rem 0 0"
                paddingSm="8rem 0 0"
              >
                Klipfolio Brand Guidelines
              </Heading>
            </Container>
          </Flex>
          <Flex background="#f3f5fc" padding="6rem 0" paddingSm="2rem 0">
            <Container>
              <Grid
                gap="2rem"
                columns="repeat(3, 1fr)"
                columnsMd="1fr 1fr"
                columnsSm="1fr"
              >
                <Flex {...cardSettings}>
                  <Heading as="h4">Klipfolio Logo</Heading>
                  <Image
                    style={{ alignSelf: "center" }}
                    objectFit="scale-down"
                    height={200}
                    width={200}
                    file={images["efbdf06d-c41c-4261-b50a-0dad5d0cfbf7"]}
                  />
                  <Anchor
                    link="/branding-guidelines/klipfolio-logo"
                    margin="auto 0 0"
                    arrow
                  >
                    View Logo Guidelines
                  </Anchor>
                </Flex>
                <Flex {...cardSettings}>
                  <Heading as="h4">Klipfolio Badge</Heading>
                  <Image
                    style={{ alignSelf: "center" }}
                    objectFit="scale-down"
                    height={200}
                    width={200}
                    file={images["39f741e6-5662-44ee-85f9-3672d68c3666"]}
                  />
                  <Anchor
                    link="/branding-guidelines/klipfolio-badge"
                    margin="auto 0 0"
                    arrow
                  >
                    View Badge Guidelines
                  </Anchor>
                </Flex>
                <Flex {...cardSettings}>
                  <Heading as="h4">Klipfolio Colours</Heading>
                  <Image
                    style={{ alignSelf: "center" }}
                    objectFit="scale-down"
                    height={200}
                    width={200}
                    file={images["565ab68a-105f-47e6-a0d2-05b8bdacb75f"]}
                  />
                  <Anchor
                    link="/branding-guidelines/klipfolio-colours"
                    margin="auto 0 0"
                    arrow
                  >
                    View Colour Guidelines
                  </Anchor>
                </Flex>
              </Grid>
            </Container>
          </Flex>
        </Flex>
      </Container>
    </Layout>
  )
}

BrandingGuidelinesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BrandingGuidelinesPage

export const pageQuery = graphql`
  query BrandingIndexQuery {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "39f741e6-5662-44ee-85f9-3672d68c3666"
            "565ab68a-105f-47e6-a0d2-05b8bdacb75f"
            "efbdf06d-c41c-4261-b50a-0dad5d0cfbf7"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
  }
`
